@import '../_variables'
@import '../_mixins'

.tabs-container
    +flexbox(flex-start, center, row, nowrap)
    overflow-x: auto
    border-bottom: 1px solid rgba(0, 0, 0, .14)

.tab
    cursor: pointer
    font-weight: 500
    font-size: .9em
    padding: 16px
    position: relative

.selected-tab
    color: $red

    &::after
        content: ''
        position: absolute
        bottom: 0
        left: 0
        height: 2px
        width: 100%
        background-color: $red