@import './../_mixins'
@import './../_variables'

.section-title
    font-size: 21px
    opacity: .86
    letter-spacing: 0.03px
    font-weight: 700
    margin: 16px 0

.dot-container
    position: relative
    width: 30px
    +flexbox(center, center, column, nowrap)

    .dot-tooltip
        position: absolute
        top: -32px
        left: 0
        z-index: 100
        background-color: rgba(0, 0, 0, .54)
        color: #fff
        font-size: .9em
        padding: 4px 8px
        border-radius: 4px
        opacity: 0
        transition: opacity 200ms linear

.dot-container:hover
    .dot-tooltip
        opacity: 1

.input-select-row
    +flexbox(flex-start, center, row, nowrap)

    &--subsection
        +flexbox(flex-start, center, row, nowrap)
        gap: 16px

    &--subsection:not(:first-child)
        padding-left: 16px

    &--subsection:not(:last-child)
        padding-right: 16px
        border-right: 1px solid rgba(0, 0, 0, .14)

.dynamic-parameters-section
    +flexbox(flex-start, flex-start, column, nowrap)

    .parameter-element
        +flexbox(flex-start, center, row, nowrap)

        &--label
            font-size: .8em
            font-weight: 500
            margin-right: 16px
            width: 250px
            max-width: 250px

.device-main-info-wrapper:nth-child(n+2)
    margin-top: 32px

.device-main-info-wrapper
    position: relative
    width: 100%
    display: flex
    justify-content: flex-start
    align-items: flex-start
    flex-direction: column
    flex-wrap: wrap
    gap: 32px
    max-width: 95%
    padding: 20px
    border-radius: 20px

    &-header
        position: relative
        width: 100%
        display: flex
        justify-content: space-between
        align-items: center
        flex-direction: row
        flex-wrap: wrap

    &--column
        +flexbox(flex-start, flex-start, column, nowrap)
        width: 100%
        background-color: #f8f8f8
        padding: 20px
        border-radius: 20px

        &--section
            +flexbox(flex-start, flex-start, column, nowrap)
            max-width: 900px
            width: 80%

            &__input-group
                +flexbox(flex-start, flex-start, row, nowrap)
                gap: 16px
                position: relative
                min-width: 100%

                .selectedInput
                    max-width: 100% !important

                .input-group-label
                    width: 95%
                    position: relative
                    font-size: .8em
                    font-weight: 500
                    padding-top: 16px

            .group-larger-first-element
                .selectedInput:not(:first-child)
                    margin-left: 10px

.snmp-community-grants-container
    align-items: center
    display: grid
    grid-template-columns: 200px repeat(2, 160px)
    gap: 16px

.group-larger-first-element
    flex-direction: row !important
    
    .selectedInput:first-child
        max-width: 200px !important

.localization-section
    grid-area: 2 / 1 / 2 / 3

.weekdays-checkboxes-grid
    display: grid
    grid-template-columns: 1fr 1fr
    grid-template-rows: 1fr 1fr 1fr

.fast-command-row
    position: relative
    +flexbox(flex-start, center, row, nowrap)
    border-bottom: 1px solid rgba(0, 0, 0, .14)
    padding-bottom: 16px
    margin-bottom: 16px
    padding-left: 16px
    gap: 16px
    z-index: 80

.empty-device-tree-message
    font-size: .9em
    font-weight: 500
    text-align: center
    margin: 16px 0
    opacity: .6

.device-tree-container
    +flexbox(flex-start, flex-start, column, nowrap)
    padding-left: 32px

    .master-device-label
        color: #000
        font-size: .9em
        letter-spacing: .3px
        font-weight: 500

    .children-slot
        position: relative
        +flexbox(center, center, row, nowrap)

        .children-status-dot
            position: absolute
            z-index: 1
            top: 39%
            left: 30px
            width: 10px
            height: 10px
            border-radius: 50%

        .normal-status
            background-color: #fff
            border: 2px solid #a1a1a1

        .alert-status
            background-color: $red
            border: 2px solid $red
            box-shadow: 0px 0px 0px 2px rgba(225, 9, 21, .2)

        .children-device-label
            +flexbox(flex-start, flex-start, row, nowrap)
            font-size: .9em
            margin: 0
            padding: 16px 0 16px 56px
            letter-spacing: 0.4px
            position: relative
            // color: $red
            // text-decoration: underline
            // cursor: pointer

            &::after
                position: absolute
                left: 8px
                top: 50%
                content: ''
                height: 2px
                width: 30px
                background-color: rgba(0, 0, 0, .24)

        .remove-element-icon
            cursor: pointer
            margin-left: 16px

    .children-slot:not(:last-child)
        .children-device-label
            &::before
                position: absolute
                left: 8px
                top: 0
                content: ''
                height: 100%
                width: 2px
                background-color: rgba(0, 0, 0, .24)

    .children-slot:last-child
        .children-device-label
            &::before
                position: absolute
                left: 8px
                top: 0
                content: ''
                height: 50%
                width: 2px
                background-color: rgba(0, 0, 0, .24)

.section-title
    +flexbox(flex-start, center, row, nowrap)

.device-status-container
    display: inline-flex
    gap: 12px
    justify-content: flex-start
    align-items: center
    margin-left: 16px

    > label
        font-weight: 400
        font-size: .75em

    .device-status-label
        line-height: 1
        padding: 4px
        margin-left: 8px
        border-radius: 4px
        font-weight: 600
        font-size: 12px
        letter-spacing: .3px

    .device-status-normal
        color: #fff
        background-color: #26c000

    .device-status-unreachable
        color: #fff
        background-color: #f57c35

    .device-status-alert
        color: #fff
        background-color: #e10915

.device-page-select
    min-width: 190px !important

    .labelInput
        width: auto !important

.simple-form-label
    font-size: .9em
    font-weight: 500
    word-break: keep-all
    min-width: 160px

.form-medium-label
    font-size: .95em
    padding-right: 16px

.flex-section-header
    +flexbox(flex-end, center, row, nowrap)

.creation-alert-message,
.edit-info-message
    +flexbox(flex-start, flex-start, row, nowrap)
    padding: 12px 24px
    border-radius: 4px
    font-size: .9em
    font-weight: 500
    flex-grow: 1

.creation-alert-message
    background-color: rgba($red, .4)
    border: 1px solid $red
    color: #fff

.edit-info-message
    margin: 12px
    background-color: rgba(#26c000, .4)
    border: 1px solid #26c000
    color: rgba(0, 0, 0, .75)

.device-detail-map-container
    z-index: -1
    height: 250px
    width: 600px
    margin: 8px 0
    border-radius: 8px
    overflow: hidden
    border: 1px solid rgba(0, 0, 0, .14)
    +flexbox(center, center, column, nowrap)

    >*
        z-index: -1

.row-with-invisible-space
    display: grid
    grid-template-columns: auto auto

.send-command-loader-container
    position: relative
    width: 64px