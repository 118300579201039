@import './../_mixins'
@import './../_variables'

.alert-modal-wrapper
    z-index: 500
    overflow: hidden
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    +flexbox(center, center, column, nowrap)

    .dark-cover
        z-index: -1
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        background-color: rgba(0, 0, 0, .34)

    .modal.edit
        z-index: 1
        +flexbox(center, center, column, nowrap)
        width: 56vw
        min-width: 800px
        height: auto
        background-color: #fff
        padding: 16px
        border-radius: 8px

        .headerSection.addNewSection
            width: calc(100% - 10px)
            padding-top: 0

        .advanced-search-wrapper
            display: grid
            grid-template-columns: repeat(3, 1fr)
            gap: 40px
            justify-content: flex-start
            align-items: flex-start
            flex-direction: column
            flex-wrap: nowrap
            width: calc(100% - 32px)
            padding: 16px
            margin-top: 20px
            background-color: #F8F8F8
            border-radius: 20px

            .filter-column h3
                margin-bottom: 5px

    .medium-modal
        overflow-y: auto

    .modal
        z-index: 1
        +flexbox(flex-start, center, column, nowrap)
        background-color: #fff
        padding: 16px
        border-radius: 8px
        margin: 32px 0
        max-width: 95%

        &--message
            font-size: 14px
            color: #000
            letter-spacing: 0.3px

        &--actions-container
            +flexbox(flex-end, center, row, nowrap)

            .confirm-action,
            .close-action
                cursor: pointer
                -moz-appearance: none
                -webkit-appearance: none
                -ms-progress-appearance: none
                border: none
                border-radius: 4px
                padding: 8px 16px
                font-weight: 500

            .confirm-action
                background-color: $red
                color: #fff
                margin-right: 8px

            .close-action
                background-color: #eee
                color: #000
                margin-left: 8px

.delete-modal
    min-width: 300px

.progress-bar-container
    position: relative
    width: 100%
    justify-content: flex-end
    align-items: center
    flex-direction: column
    text-align: right
    margin-bottom: -16px

.file-import-element
    cursor: pointer
    margin: 8px 0 16px 0

.example-file-hint
    position: relative
    width: 90%
    +flexbox(center, flex-start, column, nowrap)
    padding: 8px
    margin: 8px 0 16px 0
    border-radius: 4px
    border: 1px solid rgba(0, 0, 0, .24)
    background-color: rgba(0, 0, 0, .03)

    > a
        text-decoration: underline

.json-data-list-element
    display: grid
    grid-template-columns: repeat(3, 1fr)
    column-gap: 32px
    margin: 1em
    padding: 0

    > li
        margin: 4px 0
        +flexbox(flex-start, center, row, nowrap)

.json-data-list-element__attribute-name
    font-weight: 600
    margin: 0 8px 0 0

.display-changes-modal
    position: relative
    max-width: 60%
    height: auto !important
    width: 60vw !important

    .changes-elements-container
        position: relative
        max-width: 90%
        margin: 32px auto
        padding-top: 16px
        +flexbox(stretch, stretch, row, nowrap)
        gap: 16px

        .changes-element
            position: relative
            +flexbox(stretch, stretch, column, nowrap)
            min-width: 50%
            word-break: break-word

            code
                background-color: #F8F8F8
                padding: 10px
                border-radius: 10px
                flex: 1
                word-break: break-word

.new-var-wrapper
    overflow-y: scroll
    max-height: 80vh
    gap: 0 !important
    grid-gap: 0 !important
    grid-template-columns: none !important
    grid-template-rows: none !important

    .elements-row:first-child
        padding-bottom: 16px
        margin-bottom: 16px
