.containerLogin
    width: 22vw
    background-color: #f2f2f2
    padding: 20px

    .logoLogin
        height: auto
        width: 50%
        margin-bottom: 5vh
        align-self: flex-start
        display: block

    .inputLoginContainer
        width: 100%

        .inputLogin
            display: block
            font-size: 15px
            padding: 8px
            margin: 0px
            border-radius: 24px
            background-color: #ffffff
            border: 1px solid #ffffff
            outline: none
            margin-top: 15px
            width: calc(100% - 25px)

        .inputLogin::placeholder
            opacity: 0.6

    .inputButton
        margin-top: 15px
        margin-right: 8px
        width: 100%
        text-align: center
        padding: 8px 
        border-radius: 24px
        cursor: pointer
        color: #ffffff
        border: 1px solid #e10915
        background-color: #e10915
        font-size: 15px

    .footerLogin
        align-self: flex-start
        color: #666
        font-weight: 400
        font-size: 10px
        line-height: normal
        margin: 0

@media screen and ( max-width: 992px )
    .containerLogin
        width: 90vw
