@import './../animations'

.header
    position: fixed
    display: flex
    top: 0
    left: 0
    right: 0
    z-index: 100
    background-color: #ffffff
    flex-direction: row
    height: 70px
    border-bottom: 1px solid #e2e2e2
    justify-content: space-between
    align-items: center
    padding: 0 20px

    .leftHeader
        flex: 1
        align-items: center
        justify-content: space-between
        display: flex
        max-width: 30vw

        .logo
            width: 200px
            height: 50px
            object-fit: cover
            margin-top: 20px
            overflow: hidden

        .logosGroup
            height: 22px

    .rightHeader
        flex: 1
        max-width: 35vw
        justify-content: flex-end
        align-items: center
        display: flex
        gap: 8px

        .usernameHeader
            font-size: 14px
            color: #666

        .menuHeader
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            cursor: pointer

.overlayHeader
    position: fixed
    top: 65px
    right: 10px
    width: calc(300px - 40px)
    font-size: 14px
    color: #b0b0b0
    text-align: left
    padding: 10px 20px
    z-index: 130
    border: 1px solid #e2e2e2
    background-color: #ffffff

    .triangle
        position: absolute
        top: -22px
        right: 16px
        width: 0
        height: 0
        border: solid 11px transparent
        border-bottom: solid 11px #e1e1e1
        pointer-events: none
        z-index: 106

    .triangle:after
        position: absolute
        top: -9px
        left: -11px
        width: 0
        height: 0
        content: ""
        border: solid 11px transparent
        border-bottom: solid 11px #ffffff

    .header-list-item
        padding: 8px 0px
        line-height: 22px
        &:not(:last-child)
            border-bottom: 1px solid #e2e2e2

    .header-list-content
        color: #e10915
        display: block
        display: flex
        flex-direction: row
        align-items: center
        background-repeat: no-repeat
        background-position: left center
        list-style-type: none
        cursor: pointer

        img
            margin-right: 10px

    ul
        padding: 0
        margin: 0

.sidemenu
    display: flex
    position: fixed
    height: 100%
    flex-direction: column
    width: 210px
    padding: 20px
    margin-top: 70px
    background-color: #fff
    overflow-y: auto
    border-right: 1px solid #e2e2e2

    &.collapsed
        width: 50px
        padding: 0
        cursor: pointer

    .nav-item
        color: #3f3f3f
        line-height: 22px
        cursor: pointer
        border: none
        text-align: left
        outline: none
        font-size: 18px
        background-color: #fff
        font-weight: 500
        border-bottom: 1px solid #e2e2e2
        z-index: 100

        a
            color: #3f3f3f
            display: block
            position: relative

            &:hover
                background: rgba(255, 255, 255, 0.3)

        .main
            font-weight: 500
            padding: 10px
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center

        .item-arrow
            position: absolute
            display: block
            top: 54%
            right: 10px
            transform: translate(0, -50%)

        .badge
            position: absolute
            top: 50%
            left: 10px
            transform: translate(0, -50%)

        .submenu.show
            display: block
            height: 70%
            overflow: scroll
            scrollbar-width: thin

        .submenu.hide
            display: none

        .submenu
            list-style-type: none
            margin: 0
            padding: 0
            font-weight: 300

            & li
                margin: 0
                padding: 15px 45px

            & a
                text-decoration: none !important

        .navigateLink
            cursor: pointer
            padding: 5px 15px

            &.main
                padding: 10px

            &:hover, &.active
                background: #efefef

            &.collapseMenu
                background: #fff !important

                .collapseMenuButton
                    width: 25px
                    height: 25px
                    display: flex
                    justify-content: center
                    align-items: center
                    border-radius: 13px

                    &:hover
                        background: #efefef

.absolute-loader-container
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0
    background-color: rgba(255, 255, 255, .5)

.loader-container
    position: relative
    width: 100%
    height: 80vh

.absolute-loader-container,
.loader-container
    display: flex
    justify-content: center
    align-items: center

    .loader
        height: 32px
        width: 32px
        clear: both
        margin: 20px 0
        border: 4px solid rgba(51, 136, 255, .5)
        border-top: 4px solid rgba(51, 136, 255, 1)
        border-radius: 50%
        -webkit-animation: spin 600ms linear infinite
        -moz-animation: spin 600ms linear infinite
        animation: spin 600ms linear infinite

.footer-link-container
    margin-top: 16px
    display: flex
    justify-content: flex-start
    align-items: center
    flex-direction: row
    gap: 8px

    > a
        font-weight: bold

.logo
    width: 200px
    height: 50px
    object-fit: cover
    margin-top: 20px
    overflow: hidden
