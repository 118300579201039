@import './../_mixins'
@import './../_variables'

*:disabled
    cursor: not-allowed !important
    opacity: .7

.page-container
    flex-direction: column
    flex: 1
    margin-top: 71px

    .row-header-section
        align-items: center !important

        .header-date-selector
            justify-content: flex-end
            width: auto
            flex-grow: 1

        .introTodayAlarms
            justify-content: flex-end !important
            margin-bottom: 0 !important
            font-size: 1.2em

    .headerSection
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: flex-start
        flex-wrap: nowrap
        border-bottom: 1px solid #e2e2e2
        font-size: 18px
        font-weight: 500
        color: #3f3f3f
        line-height: 22px
        padding: 20px
        gap: 20px

        .header-section-info-text
            display: flex
            flex-direction: column
            justify-content: flex-start
            align-items: flex-start
            flex-wrap: nowrap
            width: auto
            position: relative

            .section-description
                font-size: 12px
                color: rgba(0, 0, 0, .6)
                letter-spacing: .2px

    .enabledDot
        background: #26c000
        border: 2px solid #26c000
        box-shadow: 0px 0px 0px 2px rgba(38, 192, 0, .2)

    .headerSection h2
        font-size: 18px
        font-weight: 500
        color: #3f3f3f
        line-height: 22px

    .headerSection.addNewSection
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: flex-start

        .titleLink
            color: rgba(243,11,42,0.5)
            cursor: pointer

    .body
        padding: 20px 20px 50px

    .no-padding
        padding: 0 !important

    .no-left-right-padding
        padding-left: 0 !important
        padding-right: 0 !important

    .header-right-actions
        display: flex
        flex-direction: row
        justify-content: flex-end
        align-items: center
        flex-wrap: nowrap

    .header-rapid-search
        font-size: .7em
        margin: -7px 0 -7px

    .sectionContent
        padding: 20px

        .editContainer
            padding: 20px
            background-color: #f8f8f8
            border-radius: 20px
            display: grid
            grid-template-columns: repeat(3, 1fr)
            gap: 15px

    .downloadLink
        color: #e10915

    .MuiTableCell-head div
        font-weight: 500 !important
        font-size: 14px !important
        color: #3f3f3f !important
        font-family: 'Noto Sans JP', sans-serif !important

    .MuiPaper-elevation2
        box-shadow: none !important

    .MuiToolbar-regular
        min-height: 0 !important

    .dotStandard
        width: 10px
        height: 10px
        border-radius: 10px
        border: 2px solid #3f3f3f
        display: block

    .largeDot
        background-color: rgba(38, 192, 0, .7) !important
        margin-left: -1px !important
        width: 24px !important
        height: 24px !important
        border-radius: 50% !important
        display: flex !important
        justify-content: center
        align-items: center
        flex-direction: column
        font-size: .9em
        color: #fff

    .low-level-threshold-dot,
    .medium-level-threshold-dot,
    .high-level-threshold-dot
        border: 2px solid !important

    .low-level-threshold-dot
        box-shadow: 0px 0px 0px 3px rgba(0, 123, 255, .9) !important

    .medium-level-threshold-dot
        box-shadow: 0px 0px 0px 3px rgba(255, 147, 0, .9) !important

    .high-level-threshold-dot
        box-shadow: 0px 0px 0px 3px rgba(255, 86, 48, .9) !important

    .dotAlert
        width: 10px
        height: 10px
        border-radius: 10px
        display: block
        margin-left: 10px

    .warnDot
        background: #fdc500
        border: 2px solid #fdc500
        box-shadow: 0px 0px 0px 3px rgba(253, 197, 0, .2)

    .alarmDot
        background: #e10915
        border: 2px solid #e10915
        box-shadow: 0px 0px 0px 3px rgba(225, 9, 21, .2)

    .unknownDot
        background: #eee
        border: 2px solid #6c6c6c
        box-shadow: 0px 0px 0px 3px #eee

    .disabledDot
        background: #6c6c6c
        border: 2px solid #6c6c6c
        box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.2)

    .enabledDot
        background: #26c000
        border: 2px solid #26c000
        box-shadow: 0px 0px 0px 3px rgba(38, 192, 0, .2)

    .unreachableDot
        background: #1174dd
        border: 2px solid #1174dd
        box-shadow: 0px 0px 0px 3px rgba(17, 116, 221, .2)

    .regularDot
        position: relative
        background: inherit !important
        border: 2px solid rgba(0, 0, 0, .24)
        color: #000

    .unreachableDot
        opacity: .75

    .colorRed
        color: #e10915
        cursor: pointer

    .tableElement
        display: flex
        flex-direction: row
        align-items: flex-start
        gap: 10px

    .table-link-value
        text-decoration: underline

    .MuiTableCell-body
        font-size: 14px !important
        font-family: "Noto Sans JP", sans-serif !important

    .MuiTableCell-footer
        border-bottom: 0 !important

    .uploadButton
        background-color: #EA0B2A
        display: flex
        justify-content: center
        align-items: center
        width: 32px
        height: 32px
        border-radius: 16px
        cursor: pointer

        input[type='file']
            opacity: 0
            width: 100%
            height: 100%
            position: absolute
            top: 0
            padding: 0
            left: 0
            margin: 0
            cursor: pointer !important

    .customFileUpload
        padding: 6px 12px
        cursor: pointer !important
        color: #3f3f3f
        border: 1px solid #e10915
        font-weight: 400
        text-align: center
        display: inline-block
        position: relative
        width: 100%
        margin-bottom: 10px

    .selected-input
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        min-width: 125px
        width: 100%
        margin: 7px 0

    .rowInputContainer
        display: flex
        flex-direction: row !important

    .label-input-no-margin
        width: auto !important
        margin-right: 0 !important

    .selectedInput
        display: flex
        flex-direction: column
        justify-content: center
        min-width: 125px
        align-items: flex-start
        width: 100%
        margin: 7px 0
        max-width: 24vw

    .selectedInput,
    .selected-input

        .label-input
            color: #333333
            display: flex
            text-align: right
            align-items: center
            justify-content: flex-end
            width: 7vw
            line-height: 1
            margin-right: 20px
            font-size: 14px
            font-weight: 400

        .labelInput
            color: #acacac
            font-weight: 400
            margin-right: 20px
            width: 10vw
            display: flex
            text-align: right
            justify-content: flex-end
            padding-bottom: 6px

        .selectFieldContainer
            flex: 2
            width: 100%
            display: flex

            textarea.text-field
                height: 10vh
                font-family: 'Raleway', sans-serif
                resize: none
                border-radius: 5px

            .text-field
                border: 1px solid #BCBCBC
                position: relative
                width: 100%
                max-width: 22vw
                padding: 8px
                transition: border-color 200ms ease-in-out
                border-radius: 5px

                &:focus
                    border-color: rgba(0, 0, 0, .54)
                    outline: 0

            .inputField
                border: 0
                border: 1px solid rgba(0, 0, 0, .24)
                width: 100%
                padding-bottom: 5px
                border-radius: 6px
                color: #3f3f3f

                &:focus
                    outline: 0

                &:disabled
                    background: #fff
                    opacity: 0.6

        .checkboxInput
            color: #fff
            width: 25px
            height: 25px
            border-radius: 1px
            cursor: pointer
            flex-shrink: 0

            &:checked::before
                width: 25px
                height: 25px
                display: flex
                justify-content: center
                align-items: center
                font-size: 20px
                content: "✔"
                background: #e10915 -19px top no-repeat

.progress-bar
    position: relative
    display: flex
    margin: 8px auto
    justify-content: flex-start
    align-items: center
    flex-direction: row
    flex-wrap: nowrap
    width: 100%
    height: 8px
    background-color: rgba(0, 0, 0, .07)
    border-radius: 2em

    &__internal-bar
        position: relative
        height: 100%
        background-color: #26c000
        border-radius: 2em
        transition: width .3s ease-out

.base-font-size
    font-size: .9em !important

.error-message
    font-size: 14px
    margin-bottom: 0
    color: red
    padding-left: 16px

.checkbox-padding-removal
    margin-left: -6px

.checkbox-padding-removal>.selectedInput
    justify-content: flex-start !important
    margin-left: 5px !important

.multiple-action-per-element
    display: flex
    justify-content: flex-start
    align-items: center
    flex-direction: column
    flex-wrap: nowrap
    gap: 20px

    .action-icon-container
        display: flex
        justify-content: flex-start
        align-items: center
        flex-direction: column
        flex-wrap: nowrap
        box-sizing: border-box
        padding: 8px
        background: transparent
        border-radius: 50%
        transition: background 250ms ease-in-out

        > svg
            cursor: pointer !important

        &:hover
            background: rgba(0, 0, 0, .07)

.input-column,
.input-row
    display: flex
    flex-wrap: nowrap

.input-column
    justify-content: center
    align-items: center
    flex-direction: column

.input-row
    justify-content: flex-start
    align-items: flex-start
    flex-direction: row

.MuiIconButton-root:hover
    background-color: transparent !important

.MuiTableRow-root:empty
    height: 0 !important

.MuiTableSortLabel-root
    cursor: pointer

.table-sort-arrow
    opacity: 1 !important
    visibility: visible
    //transform: rotateZ(-90deg)
    transition: transform 200ms linear

.arrow-down
    transform: rotateZ(180deg)

.inactive-arrow
    transform: rotateZ(-90deg)
    color: rgba(0, 0, 0, .3)

.calendar-selection-container
    z-index: 99

    .calendar-trigger-button-disabled
        opacity: .7
        cursor: not-allowed
        pointer-events: none

    .calendar-trigger-button
        cursor: pointer
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        background-color: #fff
        border: 1px solid rgba(0, 0, 0, .34)
        border-radius: 5px
        color: #e10915
        padding: 8px
        height: 16px
        width: 16px

        .date-range-label
            font-size: 14px
            font-weight: 500
            letter-spacing: 0.3px
            margin: 1em 0 16px 1em

.scheduler-details-grid
    padding: 20px
    display: grid
    grid-template-columns: 1fr 1fr
    grid-template-rows: 1fr auto
    gap: 32px
    max-width: 1200px

    > .full-width-column
        height: 100px

    > .filter-column
        border-radius: 2px
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)
        padding: 8px 32px 32px
        max-width: 100% !important

        .section-title
            font-size: 21px
            font-weight: 700
            letter-spacing: .03px
            margin: 16px 0
            opacity: .86

.empty-input-space
    position: relative
    height: 100%
    min-width: 164px

.breadcrumb-index
    cursor: pointer

    &:hover
        color: #e10915
        text-decoration: underline

.charts-wrapper
    display: flex
    justify-content: center
    align-items: center
    flex-direction: row
    flex-wrap: wrap
    gap: 8px
    margin-top: 8px

    .chart-paragraph
        margin: 0
        line-height: 1.2em

    .empty-chart
        align-self: stretch
        justify-self: stretch
        box-shadow: none !important
        border: none !important

    .custom-chart
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column

        >h2
            font-size: 2.4em
            font-weight: 700

    .chart-container
        display: flex
        justify-content: center
        align-items: center
        border: 1px solid rgba(0, 0, 0, .14)
        position: relative
        border-radius: 5px
        padding: 5px
        width: 45%
        box-shadow: 0 3px 6px rgba(0,0,0,0.05), 0 3px 6px rgba(0,0,0,0.08)

.main-map-wrapper
    display: flex
    flex-direction: row
    height: 66vh

    .devices-markers-list
        background-color: #ffffff47
        min-width: 300px
        display: flex
        flex-direction: column
        max-height: 100%
        overflow-y: auto
        padding: 0 8px
        border: 1px solid rgba(0,0,0,.07)
        border-radius: 0 4px 4px 0

        &__element
            cursor: pointer
            padding: 16px 0
            display: flex
            justify-content: flex-start
            align-items: center
            flex-direction: row
            font-size: .9em
            font-weight: bold
            gap: 16px

            &:hover
                background-color: rgba(0, 0, 0, .05)

.device-small-map,
.main-map-container

    .leaflet-container
        position: relative
        min-height: 100%

.main-map-container
    position: relative
    height: 66vh
    z-index: 1
    width: 100%

.device-small-map
    position: relative
    height: 300px
    width: 100%
    padding-bottom: 24px
    z-index: 1

.header-date-selector
    display: flex
    justify-content: flex-end
    align-items: center
    flex-direction: row
    vertical-align: middle

.variable-field-label-container
    display: flex
    justify-content: flex-start
    align-items: center
    flex-direction: row

.required-asterisk
    margin: 0 8px
    color: red
    font-weight: bold

.table-element-logo-container
    display: flex
    justify-content: center
    align-items: flex-start
    flex-direction: column
    height: 60px
    width: 120px

    > img
        position: relative
        max-height: 100%
        max-width: 100%

.red-marker-cluster,
.blue-marker-cluster,
.yellow-marker-cluster,
.green-marker-cluster
    position: relative
    display: flex
    justify-content: center
    align-items: center
    height: 24px
    line-height: 32px
    text-align: center
    width: 24px
    border-radius: 50%
    font-weight: bold
    color: #fff

    &::before
        z-index: -1
        content: ''
        position: absolute
        left: -10%
        top: -10%
        height: 120%
        width: 120%
        border-radius: 50%

.red-marker-cluster
    background: #f44336

    &::before
        background: rgba(244, 67, 54, .4)

.green-marker-cluster
    background: #26c000

    &::before
        background: rgba(38, 192, 0, .4)

.blue-marker-cluster
    background: #1174dd

    &::before
        background: rgba(17, 116, 221, .4)

.yellow-marker-cluster
    background: #fdc500

    &::before
        background: rgba(253, 197, 0, .4)

.table-cell-checkbox-container
    .selectedInput
        justify-content: flex-start !important

// Rule set for small table within the map page
.MuiCollapse-wrapperInner > .MuiBox-root .MuiTable-root .MuiTableHead-root .MuiTableRow-root:only-child,
.MuiCollapse-wrapperInner > .MuiBox-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root:only-child
    height: 50px !important

// -- If a table has empty data in itself this rule is applied
.MuiTableBody-root .MuiTableRow-root:only-child
    height: 200px !important

.print-only
    display: none

// -- React datepicker personalization
.react-datepicker-wrapper
    position: absolute
    top: 32px
    left: 50px

.react-datepicker__input-container
    display: none !important

@media print

    .device-main-info-wrapper,
    .charts-wrapper
        display: none !important

    .print-only
        display: block

    p
        page-break-before: always

    .print-modal
        position: absolute
        z-index: 1
        left: 0
        top: 0
        width: 100%
        height: 100%
        overflow-y: visible !important
        font-size: 18px
        justify-content: flex-start !important
        align-items: flex-start !important
        padding-top: 0 !important
        margin: 0 !important
        //margin-top: 150px !important

        > .modal--message
            font-size: 15px

            /** .json-data-list-element
             padding-left: 0
             grid-template-columns: repeat(1, 1fr) */

        > .modal--actions-container
            display: none

// -- Mui style personalization

.MuiTableCell-root:has(.small-cell)
    position: relative
    max-width: 50px !important
    min-width: 50px !important
    width: 50px !important

    .table-main-value
        position: absolute

// .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft:empty,
// .MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignLeft:has(div:empty)
//     width: 0 !important
//     min-width: 0 !important
//     display: none !important
