body
    margin: 0
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    font-family: 'Noto Sans JP', sans-serif
    color: #666

code
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

li
    list-style-type: none

a
    color: inherit
    text-decoration: inherit

.checkbox-round
    width: 1.3em
    height: 1.3em
    background-color: white
    border-radius: 50%
    vertical-align: middle
    border: 1px solid #BCBCBC
    appearance: none
    padding: 10px
    -webkit-appearance: none
    outline: none
    cursor: pointer

.checkbox-round:checked
    background-color: #EA0B2A

*, *::before, *::after
    box-sizing: unset !important

// -- React toastify

.Toastify
    position: absolute

.Toastify__toast
    min-height: 50px !important
    border-radius: 6px !important

.Toastify__close-button
    align-self: auto !important

// -- React Leaflet

.leaflet-control-layers
    visibility: hidden !important

.menu-item-text
    color: #666
    font-weight: 300

.system-warning-text
    color: #ff0000
    font-weight: 300
    font-size: 14px
    margin: 0
