@import './../_mixins'
@import './../_variables'

.textIntro
    font-size: 11px
    margin-right: 25px

.titleIntro
    font-size: 14px
    margin-right: 25px

.userColumns
    display: flex
    flex-direction: row

    .userColumnsMainContainer
        display: flex
        flex-direction: column
        align-items: flex-start
        padding: 10px 30px 10px 10px
        border-right: 1px solid #e2e2e2

    .userColumnsSecondContainer
        display: flex
        flex-direction: column
        align-items: flex-start
        padding: 10px

.plannedButtonSection
    padding: 30px 20px 20px 20px
    border-bottom: 1px solid #e2e2e2
    margin-bottom: 20px

.downloadFileContainer
    display: flex
    max-width: 20vw
    margin: 20px 0
    flex-direction: column

.modelSubMenu
    display: flex
    flex-direction: row
    justify-content: flex-start
    gap: 30px
    overflow-x: auto
    flex-flow: row
    margin-bottom: 30px
    border-top: 1px solid #e2e2e2
    border-bottom: 1px solid #e2e2e2
    padding: 20px 10px
    margin-top: 30px

    .menuVoice
        color: #52565A
        border-bottom: 2px solid #52565A
        padding: 0 10px 10px 10px
        font-weight: 600
        cursor: pointer

.column
    +flexbox(flex-start, flex-start, column, nowrap)