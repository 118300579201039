.textIntro
    font-size: 11px
    margin-right: 25px

.titleIntro
    font-size: 14px
    margin-right: 25px

.sendRemoteColumns
    display: flex
    flex-direction: row

    .sendRemoteColumnsMainContainer
        display: flex
        flex-direction: column
        align-items: flex-start
        padding: 10px
        border-right: 1px solid #e2e2e2
        flex: 1

    .sendRemoteSecondContainer
        display: flex
        flex: 1
        flex-direction: column
        align-items: flex-start
        padding: 10px

.plannedButtonSection
    padding: 30px 20px 20px 20px
    border-bottom: 1px solid #e2e2e2
    margin-bottom: 20px
