@import '../../../styles/_variables'
@import '../../../styles/_mixins'
    
.onboarding-modal
    +flexbox(center, center, column, nowrap)
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 999999

    .dark-cover
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        background-color: rgba($black, .5)

    .light-modal-panel
        background-color: $white !important

    .onboarding-modal-panel
        position: relative
        max-height: 90%
        width: 100%
        max-width: $large-space*18
        background-color: $main-background-color
        border-radius: $main-border-radius
        +flexbox(center, center, column, nowrap)

        &__header,
        &__footer,
        &__body
            width: 100%

        &__header,
        &__footer
            padding: $small-space*.8

        &__header
            +flexbox(space-between, center, row, nowrap)
            line-height: 100%
            font-weight: 600
            width: 95%
            border-bottom: 1px solid rgba($white, .14)


            .icon-button
                cursor: pointer
                +flexbox(center, center, column, nowrap)
                background: rgba($black, .07)
                +square($small-space*1.4)
                border-radius: $main-border-radius
                transition: color 150ms linear
                color: inherit

        &__body
            +flexbox(flex-start, center, column, nowrap)

            &__image-container
                width: 100%
                max-height: 500px
                +flexbox(center, center, column, nowrap)
                overflow: hidden
                margin-bottom: $medium-space

                img
                    position: relative

                    width: 100%

            &__text-container
                font-weight: 600
                letter-spacing: .3px
                line-height: 120%
                opacity: .9
                margin-bottom: $small-space
            
            &__caption-container
                letter-spacing: .3px
                line-height: 120%
                opacity: .9
                padding-right: 5%
                padding-left: 5%
                margin-bottom: $small-space
                text-align: center

        &__footer
            +flexbox(center, center, row, nowrap)
            gap: $small-space*.4

            .step-dot
                cursor: pointer
                +square($small-space*.5)
                border-radius: 50%
                background: rgba($red, .24)

            .selected-dot
                background: $black
