@import '../_mixins'
@import '../_variables'

.buttonDelete
    background: #efefef
    padding: 8px 16px
    border-radius: 24px
    font-size: 13px
    display: flex
    justify-content: center
    align-items: center
    margin-right: 25px
    cursor: pointer

.mapColorCodesSection
    display: flex
    flex-direction: row
    align-items: center
    font-size: 11px


.mapColorCodesCircle
    width: 15px
    height: 15px
    margin: 10px
    border-radius: 20px
    cursor: pointer

.textIntro
    font-size: 11px
    margin-right: 25px

.titleIntro
    font-size: 14px
    margin-right: 25px

.introTodayAlarms
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    margin-bottom: 25px

    .nextUpdate
        font-size: 11px
        display: flex
        align-items: center

        .timeToUpdate
            color: #e10915
            margin-left: 8px

.buttonMapContainer
    position: absolute
    display: flex
    flex-direction: row
    z-index: 9999
    top: 10px
    left: 10px

    .buttonMap
        background: #e10915
        margin-right: 20px
        padding: 8px 16px
        display: flex
        flex-direction: row
        cursor: pointer
        color: #fff
        justify-content: center
        align-items: center
        border-radius: 24px
        font-size: 13px

.advancedSearchColumns
    display: flex
    flex-direction: row

    .advancedSearchMainContainer
        display: flex
        flex-direction: column
        align-items: flex-start
        padding: 10px
        border-right: 1px solid #e2e2e2
        flex: 1

    .advancedSearchSecondContainer
        display: flex
        flex: 1
        flex-direction: column
        align-items: flex-start
        padding: 10px

.spaced-inputs
    justify-content: center !important
    align-items: flex-end
    gap: 20px

    >.update-pass-button
        margin-bottom: 13px !important

.three-elements-row
    grid-template-columns: repeat(3, 1fr)

.six-elements-row
    grid-template-columns: 48px repeat(3, 180px) 216px repeat(2, 100px)

.simple-row
    display: flex
    justify-content: flex-start
    align-items: center
    flex-direction: row
    flex-wrap: nowrap
    gap: 16px

.elements-row
    display: grid
    gap: 16px
    justify-content: flex-start
    align-items: flex-start
    flex-direction: row
    flex-wrap: wrap

.middle-aligned-elements-row
    gap: 10px
    margin-top: 16px
    display: grid
    align-items: center
    grid-template-columns: repeat(auto-fit, minmax(0px, auto))

.threshold-header-row
    display: grid
    grid-template-columns: repeat(4, 1fr) repeat(2, 140px)
    align-items: center

.threshold-row
    padding: 16px 0 0
    margin-top: 16px
    border-top: 1px solid rgba(0, 0, 0, .14)
    display: flex
    justify-content: flex-start
    align-items: center
    flex-direction: row
    gap: 8px

.input-rows
    display: flex
    justify-content: flex-start
    align-items: center
    flex-direction: row
    gap: 20px
    position: relative
    width: 100%

.padding-search-wrapper
    padding: 24px
    
.single-line-input-row
    grid-template-columns: 2fr 1fr !important
    margin-top: 25px

    .filter-column
        max-width: 100% !important

.search-wrapper-single-row-container,
.advanced-search-wrapper
    z-index: 11
    position: relative
    gap: 20px
    justify-content: flex-start
    flex-wrap: nowrap
    align-items: center

    .imgUploadContainer
        background: #fff
        max-width: 40%
        border-radius: 5px
        border: 1px solid #bcbcbc
        padding: 10px
        object-fit: cover
        position: relative

        .logoBrand
            max-width: 100%

.filterSearchContainer
    border-radius: 20pt
    padding: 16px 30px
    background-color: #f9f9f9
    display: flex
    flex-direction: column

    .advanced-search-wrapper
        display: grid
        grid-template-columns: repeat(3, 1fr)
        gap: 40px
        justify-content: flex-start
        align-items: flex-start
        flex-direction: column
        flex-wrap: nowrap

    .searchButton
        background-color: #EA0B2A
        display: flex
        justify-content: flex-end
        padding: 7px 20px
        border-radius: 5px
        color: #fff
        font-size: 14px
        align-self: end
        cursor: pointer

.advanced-search-wrapper
    +flexbox(flex-start, flex-start, column, nowrap)

    .search-wrapper-body
        position: relative
        width: 100%
        display: grid
        flex-direction: column
        align-items: flex-start
        gap: 24px
        grid-template-columns: repeat(3, 360px)

.search-wrapper-single-row-container
    display: flex
    flex-direction: row
    align-items: center

.full-width-column
    grid-area: 2 / 1 / 2 / 3
    max-width: 100% !important

.filter-column
    display: flex
    justify-content: flex-start
    align-items: flex-start
    flex-direction: column
    max-width: 400px

    > h3
        margin: 0
        margin-bottom: 20px

.column-without-title
    margin-top: 45px

.filter-wrapper-action-section
    +flexbox(flex-end, center, row, nowrap)
    gap: 16px
    position: relative
    width: 100%

.filter-secondary-button,
.filter-confirm-button,
.standard-confirm-button
    -moz-appearance: none
    -webkit-appearance: none
    -ms-progress-appearance: none
    border: none
    cursor: pointer
    border-radius: 6px
    padding: 8px 16px
    font-size: 14px
    color: #fff
    font-weight: 500


.filter-confirm-button
    position: absolute
    bottom: 16px
    right: 16px
    background-color: $red

.filter-secondary-button
    color: #000
    background-color: #cfcfcf

.standard-confirm-button
    background-color: $red

.labelInput
    line-height: 1
    justify-content: flex-end

.data-column
    display: flex
    justify-content: flex-start
    align-items: center
    flex-direction: column
    width: 208px

.horizontal-divider
    position: relative
    width: 100%
    background-color: #e2e2e2
    height: 1px
    margin: 24px 0

.fiter-trigger-button
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center
    flex-direction: row
    flex-wrap: nowrap
    width: 126px
    font-size: 14px
    color: #000
    margin: 8px 0

    > span
        margin-right: 16px

.eventBadge
    background-color: #e10915
    border-radius: 20px
    width: 30px
    height: 30px
    display: flex
    justify-content: center
    align-items: center
    color: #fff

.row-calendar-container
    flex-direction: row !important
    align-items: center !important

    .date-range-label
        margin: 1em 2em 1em 0 !important

.calendar-selection-container
    display: flex
    justify-content: flex-start
    align-items: flex-start
    flex-direction: column
    font-size: 13px

    .calendar-trigger-button
        cursor: pointer
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        background-color: #fff
        border: 1px solid rgba(0, 0, 0, .34)
        border-radius: 5px
        color: #e10915
        padding: 8px
        height: 16px
        width: 16px

    .date-range-label
        font-size: 14px
        font-weight: 500
        letter-spacing: 0.3px
        margin: 1em 0 16px 1em

.single-line-event-value-block
    justify-content: center
    min-height: 40px

.table-event-value-block
    display: flex
    flex-direction: column

.table-event-value-block:not(:last-child)
    margin-bottom: 20px

.table-main-value
    top: 16px

.children-branch
    padding-left: 30px

.children-branch::before
    position: absolute
    content: ""
    left: -25px
    top: 35px
    height: 2px
    width: 26px
    background-color: #cecece

.children-branch::after
    position: absolute
    content: ''
    height: 10px
    width: 10px
    background-color: #ccc
    border-radius: 50%
    left: -8px
    top: 31px

.full-vertical-line,
.half-vertical-line
    position: absolute
    content: ''
    top: 0
    left: -25px 
    background-color: #ccc
    width: 2px

.full-vertical-line
    height: 100%

.half-vertical-line
    height: 50%

.separator-vertical-line
    position: relative
    height: 52px
    width: 1px
    margin: 0 8px
    background-color: rgba(0, 0, 0, .24)

.threshold-custom-string-container
    display: flex
    justify-content: flex-start
    align-items: center
    position: relative
    width: calc(100% - 120px)
    margin-left: 64px
    gap: 16px

// -- React datepicker personalization

.react-datepicker-wrapper
    position: absolute
    top: 32px
    left: 50px

.react-datepicker__input-container
    display: none !important
    
// -- Mui style personalization

.MuiTableCell-root:nth-child(3)
    // position: relative
    // min-width: 260px

    .table-main-value
        position: absolute